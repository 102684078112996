import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AllRoutes } from './routes/AllRoutes';
import { Header, Footer } from './components';
import { ScrollToTop } from './components';
import { Helmet } from 'react-helmet'; // Import react-helmet
import './App.css';

function App() {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="App">
      <Helmet>
        <title>BuildFast Structural Solutions | Leading Construction & Architecture Company Zimbabwe</title>
        <meta
          name="description"
          content="Build-Fast Structural Solutions offers premier architectural and construction services in Zimbabwe. Specializing in residential, commercial, and industrial projects with innovative sustainable designs. Get a free consultation today!"
          data-rh="true"
        />
        <link rel="canonical" href="https://build-fast.co.zw/" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "BuildFast Structural Solutions",
            "alternateName": ["Build Fast", "BuildFast Construction", "Build Fast Zimbabwe"],
            "url": "https://build-fast.co.zw/",
            "logo": "https://build-fast.co.zw/logo192.png",
            "description": "Leading construction and architectural design company in Zimbabwe, offering comprehensive building solutions.",
            "areaServed": {
              "@type": "Country",
              "name": "Zimbabwe"
            },
            "sameAs": [
              "https://www.facebook.com/buildfastZIM",
              "https://www.instagram.com/buildfastzw/",
              "https://www.linkedin.com/in/courage-makonese-643687331",
              "https://www.tiktok.com/@build_fast"
            ],
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Margolis Plaza, Cnr Harare st & Speke Ave",
              "addressLocality": "Harare",
              "addressRegion": "Harare",
              "postalCode": "12345",
              "addressCountry": "Zimbabwe"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+263 776 347 735",
              "contactType": "Customer Service",
              "availableLanguage": ["English", "Shona", "Ndebele"]
            },
            "hasOfferCatalog": {
              "@type": "OfferCatalog",
              "name": "Construction Services",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Architectural Design",
                    "description": "Professional architectural design services for residential and commercial projects"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Construction Services",
                    "description": "Full-scale construction services for all types of buildings"
                  }
                }
              ]
            }
          }
          `}
        </script>
      </Helmet>
      <Router>
        <ScrollToTop isLoading={isLoading} />
        <Header />
        <AllRoutes setIsLoading={setIsLoading} />
        <Footer />
      </Router>
    </div>
  );
}

export default App;

