// ScrollToTop.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function ScrollToTop({ isLoading }) {
  const { pathname } = useLocation();

  useEffect(() => {
    // Only scroll after content is loaded
    if (!isLoading) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant' // Use instant for route changes
      });
    }
  }, [pathname, isLoading]);

  return null;
}
