import { Routes, Route } from "react-router-dom";
import { About, Contacts, Home, OurServices, PageNotFound, TeamPage,Projects, Gallery, ProjectUploadForm, ServicePage, ProjectDetails } from "../Pages";
import { ImageUploader } from "../imageUploader";
import { QuotationForm, PrivacyPolicy, TermsAndConditions, SafetyCommitment, FAQ, Sustainability, CareersPage } from "../components";

export const AllRoutes = () => {
  return (
    <div>
      <Routes>

          <Route path="/" element={<Home title="Home" />} />
          <Route path="/upload" element={<ImageUploader title="Upload Image" />} />
          <Route path="/about_build_fast" element={<About title="About Us" />} />
          <Route path="/contact-us" element={<Contacts title="Contact Us" />} />
          <Route path="/team/contact-us" element={<Contacts title="Contact Us" />} />
          <Route path="/careers" element={<CareersPage title="Careers" />} />
          <Route path="/quotation" element={<QuotationForm title="Quotation Form" />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy title="Privacy Policy" />} />
          <Route path="/terms-conditions" element={<TermsAndConditions title="Terms & Conditions" />} />
          <Route path="/sustainability" element={<Sustainability title="Sustainability" />} />
          <Route path="/safety" element={<SafetyCommitment title="Safety Commitment" />} />
          <Route path="/team" element={<TeamPage title="Our Team" />} />
          <Route path="/faq" element={<FAQ title="FAQ" />} />
          <Route path="/projects" element={<Projects title="Projects" />} />
          <Route path="/projects/:id" element={<ProjectDetails title="Project Details" />} /> {/* Updated this line */}
          <Route path="/upload-project" element={<ProjectUploadForm title="Upload Project" />} />
          <Route path="/services/services" element={<OurServices title="Our Services" />} />
          <Route path="/services/:serviceId" element={<ServicePage title="Service Details" />} /> {/* Updated this line */}
          <Route path="/gallery" element={<Gallery title="Gallery" />} />
          <Route path="*" element={<PageNotFound title="Page Not Found" />} />
      </Routes>
    </div>
  );
};
