import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const faqs = [
  {
    question: "What services does BuildFast Structural Solutions offer?",
    answer: "We offer a comprehensive range of services including architectural design, engineering solutions, town planning, interior design, and project management. Our team specializes in both residential and commercial projects, from simple house plans to complex structural solutions.",
    category: "Services"
  },
  {
    question: "How much does it cost to get a house plan?",
    answer: "Our house plans are priced based on square footage. We offer various packages starting from $175 for 200-300 SQM up to $965 for 1000+ SQM double-story designs. Each package includes different features such as main house plans, cottage plans, boundary wall plans, and 3D images.",
    category: "Pricing"
  },
  {
    question: "How long does it take to complete a design project?",
    answer: "Project timelines vary depending on complexity and scope. Typically, simple house plans can be completed within 1-2 weeks, while more complex projects may take 4-6 weeks. During our initial consultation, we'll provide a more accurate timeline based on your specific requirements.",
    category: "Timeline"
  },
  {
    question: "Do you handle building approvals and permits?",
    answer: "Yes, we offer assistance with the building approval process. Our team is well-versed in local building codes and regulations, and we ensure all our designs meet the necessary requirements. We can help prepare and submit the required documentation to relevant authorities.",
    category: "Services"
  },
  {
    question: "Can you work with existing structures or renovations?",
    answer: "Absolutely! We have extensive experience in renovation projects and working with existing structures. Our team can assess your current building, propose improvements, and design solutions that blend seamlessly with the existing architecture while meeting your new requirements.",
    category: "Services"
  },
  {
    question: "Do you provide 3D visualizations of the designs?",
    answer: "Yes, we provide 3D visualizations with most of our packages. These help you better understand the design and make informed decisions. Our detailed 3D renders show how your project will look from different angles and perspectives before construction begins.",
    category: "Services"
  },
  {
    question: "What is your payment structure?",
    answer: "We typically require a 50% deposit to commence work, with the remaining balance due upon completion. For larger projects, we can arrange a phased payment schedule aligned with project milestones. We accept various payment methods including bank transfers and mobile money.",
    category: "Pricing"
  },
  {
    question: "Do you offer post-design support during construction?",
    answer: "Yes, we offer comprehensive post-design support. This includes site visits, contractor coordination, and ensuring the construction aligns with our designs. We can also provide project management services if required, overseeing the entire construction process.",
    category: "Services"
  }
];

const categories = [...new Set(faqs.map(faq => faq.category))];

export function FAQ() {
  const [activeCategory, setActiveCategory] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [openItems, setOpenItems] = useState(new Set());

  const filteredFaqs = faqs.filter(faq => {
    const matchesCategory = activeCategory === 'All' || faq.category === activeCategory;
    const matchesSearch = faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         faq.answer.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  const toggleItem = (index) => {
    const newOpenItems = new Set(openItems);
    if (newOpenItems.has(index)) {
      newOpenItems.delete(index);
    } else {
      newOpenItems.add(index);
    }
    setOpenItems(newOpenItems);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h2 className="text-4xl font-bold text-center mb-8 text-gray-900">
        Frequently Asked Questions
      </h2>
      
      {/* Search Bar */}
      <div className="mb-6">
        <div className="relative">
          <input
            type="text"
            placeholder="Search questions..."
            className="w-full p-4 pl-12 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 transition-colors"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <svg
            className="absolute left-4 top-4 h-5 w-5 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
      </div>

      {/* Category Tabs */}
      <div className="flex flex-wrap gap-2 mb-6">
        <button
          onClick={() => setActiveCategory('All')}
          className={`px-4 py-2 rounded-full transition-colors ${
            activeCategory === 'All'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          }`}
        >
          All
        </button>
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setActiveCategory(category)}
            className={`px-4 py-2 rounded-full transition-colors ${
              activeCategory === category
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            {category}
          </button>
        ))}
      </div>

      {/* FAQ Items */}
      <div className="space-y-4">
        {filteredFaqs.map((faq, index) => (
          <div
            key={index}
            className="border border-gray-200 rounded-lg overflow-hidden hover:border-blue-500 transition-colors"
          >
            <button
              onClick={() => toggleItem(index)}
              className="flex justify-between items-center w-full p-4 text-left bg-white hover:bg-gray-50 transition-colors"
            >
              <span className="font-medium text-gray-900">{faq.question}</span>
              <motion.div
                animate={{ rotate: openItems.has(index) ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              >
                <ChevronDown className="w-5 h-5 text-gray-500" />
              </motion.div>
            </button>
            <AnimatePresence>
              {openItems.has(index) && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="p-4 bg-gray-50 border-t border-gray-200">
                    <p className="text-gray-700">{faq.answer}</p>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>

      {filteredFaqs.length === 0 && (
        <div className="text-center py-8">
          <p className="text-gray-500">No matching questions found.</p>
        </div>
      )}
    </div>
  );
}