import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { ChevronRight, Building2, Ruler, Compass, Building, HardHat, ClipboardList, ArrowRight } from 'lucide-react';
import { useNavigate, Link } from 'react-router-dom';
import eng from "../Assets/eng2.jpg"
import ach from "../Assets/work.jpg"
import cost from "../Assets/team4.jpg"
import { Helmet } from 'react-helmet';

const services = [
  {
    id: 'architecture',
    title: 'Architecture',
    icon: Building2,
    description: 'We craft innovative and functional designs that harmonize with their surroundings and reflect our clients\' visions. From conceptual design to detailed documentation, our architectural services are tailored to create spaces that inspire and engage.',
    features: ['Conceptual Design', 'Detailed Documentation', '3D & 2D Drawings', 'Sustainable Design'],
    image: ach,
  },
  {
    id: 'engineering',
    title: 'Engineering',
    icon: Compass,
    description: 'Our engineering team integrates structural, mechanical, and electrical systems to ensure the seamless functionality and safety of our projects. We prioritize efficiency, sustainability, and technical excellence in every engineering solution.',
    features: ['Structural Engineering', 'MEP Systems', 'Safety Analysis', 'Technical Excellence'],
    image: eng,
  },
    {
    id: 'project-management',
    title: 'Project Management',
    icon: ClipboardList,
    description: 'Delivering projects on time, within budget, and exceeding expectations through strategic planning and execution. Our comprehensive approach ensures seamless coordination and successful delivery of construction projects of all sizes.',
    features: ['Strategic Planning', 'Resource Optimization', 'Risk Management', 'Quality Control', 'Timeline Management'],
    image: "https://images.pexels.com/photos/7376/startup-photos.jpg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    id: 'town-planning',
    title: 'Town Planning',
    icon: Building,
    description: 'We offer expert town planning services to help shape the development of communities and urban spaces. Our approach includes strategic planning, zoning analysis, and sustainable development practices.',
    features: ['Property Consolidation','Urban Design', 'Zoning Analysis', 'Sustainable Planning', 'Change of Use Permits'],
    image: 'https://images.pexels.com/photos/7937310/pexels-photo-7937310.jpeg?auto=compress&cs=tinysrgb&w=600'
  },
  {
    id: 'quantity-surveying',
    title: 'Quantity Surveying',
    icon: Ruler,
    description: 'Our quantity surveying services provide precise cost management and financial oversight throughout the project lifecycle. We ensure budget adherence, cost forecasting, and value engineering to optimize resource allocation.',
    features: ['Cost Management', 'Value Engineering', 'Contract Administration', 'Risk Assessment'],
    image: 'https://images.pexels.com/photos/4832564/pexels-photo-4832564.jpeg?auto=compress&cs=tinysrgb&w=600'
  },
  {
    id: 'construction',
    title: 'Construction',
    icon: HardHat,
    description: 'From project management to on-site supervision, our construction services are designed to bring architectural visions to life. We collaborate closely with clients and contractors to ensure high standards of quality, safety, and timely delivery.',
    features: ['Project Management', 'Quality Control', 'Safety Compliance', 'Timeline Optimization'],
    image: cost,
  },
];

export const OurServices = ({title}) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <main className="min-h-screen bg-white">
      <Helmet>
        <title>Professional Construction & Architecture Services - BuildFast Zimbabwe</title>
        <meta name="description" content="Explore BuildFast's comprehensive construction and architectural services in Zimbabwe. From design to completion, we deliver excellence in every project." />
        <meta property="og:title" content="Professional Construction & Architecture Services - BuildFast Zimbabwe" />
        <meta property="og:description" content="Explore BuildFast's comprehensive construction and architectural services in Zimbabwe. From design to completion, we deliver excellence in every project." />
        <link rel="canonical" href="https://build-fast.co.zw/services" />
      </Helmet>
      {/* Hero Section - Enhanced but keeping same content */}
      <section className="relative h-[80vh] flex items-center justify-center bg-gradient-to-br from-blue-800 to-blue-900 overflow-hidden">
        <div className="relative z-10 max-w-7xl mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="flex flex-col items-center gap-6"
          >
            <div className="w-20 h-1 bg-yellow-400 rounded-full mb-2"></div>
            
            <motion.h1
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-4xl md:text-6xl lg:text-7xl font-bold text-white mb-6"
            >
              Our Professional{' '}
              <span className="text-yellow-400">Services</span>
            </motion.h1>

            <motion.p
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              className="text-xl md:text-2xl text-gray-200 max-w-3xl mx-auto"
            >
              Comprehensive solutions for all your architectural and engineering needs
            </motion.p>

            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.6 }}
              className="mt-8"
            >
              <Link
                to="/contact-us"
                className="px-8 py-4 bg-yellow-400 text-blue-800 font-semibold rounded-xl 
                         hover:bg-yellow-300 transition-colors duration-300 
                         flex items-center justify-center gap-2 group"
              >
                Get Started
                <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </motion.div>
          </motion.div>
        </div>

        <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-white to-transparent"></div>
      </section>

      {/* Services Grid Section - Enhanced with existing content */}
      <section className="py-24 px-4 bg-gradient-to-br from-gray-50 to-white relative overflow-hidden">
        <div className="absolute inset-0 opacity-10">
          <div className="absolute top-0 left-0 w-96 h-96 bg-blue-800 rounded-full filter blur-3xl -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 right-0 w-96 h-96 bg-yellow-400 rounded-full filter blur-3xl translate-x-1/2 translate-y-1/2"></div>
        </div>

        <div className="max-w-7xl mx-auto relative z-10">
          <div className="text-center mb-16">
            <motion.span 
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              className="text-blue-800 font-semibold mb-4 flex items-center justify-center gap-2"
            >
              <div className="h-1 w-12 bg-yellow-400"></div>
              What We Offer
              <div className="h-1 w-12 bg-yellow-400"></div>
            </motion.span>
            <motion.h2 
              initial={{ y: -20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
              className="text-4xl font-bold text-blue-800 mb-6"
            >
              Our Expertise
            </motion.h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <motion.div
                key={service.title}
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col"
              >
                <div className="h-64 relative overflow-hidden group">
                  <img 
                    src={service.image}
                    alt={service.title}
                    className="absolute inset-0 w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-blue-800/90 via-blue-800/40 to-transparent opacity-0 group-hover:opacity-100 transition-all duration-300"></div>
                </div>
                <div className="p-6 flex-grow">
                  <div className="flex items-center mb-4">
                    <service.icon className="w-8 h-8 text-blue-600 mr-3" />
                    <h2 className="text-2xl font-bold text-gray-900">{service.title}</h2>
                  </div>
                  <p className="text-gray-600 mb-6">{service.description}</p>
                  <div className="mb-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-3">Key Features:</h3>
                    <ul className="space-y-2">
                      {service.features.map((feature, featureIndex) => (
                        <li key={featureIndex} className="flex items-center text-gray-600">
                          <ChevronRight className="w-4 h-4 text-blue-600 mr-2 flex-shrink-0" />
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="p-6 pt-0">
                  <button
                    onClick={() => navigate(`/services/${service.title.toLowerCase().replace(' ', '-')}`)}
                    className="w-full bg-yellow-400 hover:bg-yellow-500 text-blue-800 font-bold py-3 px-6 rounded-lg transition duration-300 flex items-center justify-center group"
                  >
                    Learn More
                    <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform" />
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Enhanced CTA Section */}
      <section className="py-16 bg-gradient-to-br from-blue-800 to-blue-900 relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute top-0 left-0 w-96 h-96 bg-yellow-400/20 rounded-full filter blur-3xl -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 right-0 w-96 h-96 bg-white/10 rounded-full filter blur-3xl translate-x-1/2 translate-y-1/2"></div>
        </div>

        <div className="max-w-7xl mx-auto px-4 text-center relative z-10">
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="space-y-8"
          >
            <h2 className="text-4xl font-bold text-white">Ready to Start Your Project?</h2>
            <p className="text-xl text-gray-200 max-w-2xl mx-auto">
              Let's bring your vision to life. Contact us today for a consultation.
            </p>
            <Link
              to="/contact-us"
              className="inline-flex items-center justify-center px-8 py-4 bg-yellow-400 text-blue-800 font-semibold rounded-xl 
                       hover:bg-yellow-300 transition-colors duration-300 gap-2 group"
            >
              Get in Touch
              <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </Link>
          </motion.div>
        </div>
      </section>
    </main>
  );
};


