import React from 'react';

export const TermsAndConditions = () => {
  return (
    <section className="bg-gray-50 py-12 px-6 md:px-12">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-8">
        <h1 className="text-3xl font-bold text-center mb-6">
          Terms & Conditions
        </h1>
        <p className="text-lg text-gray-700 mb-6">
          Welcome to our digital sketchpad! By using our services, you agree to the following terms and conditions, so we can continue to create exceptional spaces for you.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
          Acceptance of Terms
        </h2>
        <p className="text-gray-700 mb-6">
          By accessing and using our website, you are acknowledging that you have read, understood, and agree to be bound by these terms. If you do not agree, we kindly ask that you refrain from using our services.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
          Intellectual Property
        </h2>
        <p className="text-gray-700 mb-6">
          All content on this site, including designs, concepts, and text, is the property of our firm and is protected under intellectual property laws. Unauthorized use, reproduction, or distribution of our work is strictly prohibited without prior consent.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
          Limitation of Liability
        </h2>
        <p className="text-gray-700 mb-6">
          While we strive for accuracy, we do not guarantee that our services will be error-free, nor do we make any promises about the accuracy of the information on our site. We shall not be liable for any damages resulting from your use of our website.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
          Changes to the Terms
        </h2>
        <p className="text-gray-700 mb-6">
          We reserve the right to update these terms and conditions at any time. Any changes will be reflected on this page, so please check back periodically to stay informed.
        </p>

        <p className="text-lg text-gray-800 font-semibold mt-8 mb-6">
          Thank you for respecting our terms!
        </p>
        <p className="text-gray-700 mb-6">
          For any questions regarding these terms, feel free to contact us at <a href="mailto:info@example.com" className="text-blue-600 hover:underline">info@example.com</a>.
        </p>
      </div>
    </section>
  );
};

