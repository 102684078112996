/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import background from "../Assets/back.png"
import { Award, Target, Eye, Compass, TrendingUp, Shield, ChevronRight, CheckCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const values = [
  {
    icon: <Target className="w-12 h-12 text-blue-600" />,
    title: "Innovation",
    description: "Embrace creativity and forward-thinking solutions to push the boundaries of design and functionality. "
  },
  {
    icon: <Shield className="w-12 h-12 text-blue-600" />,
    title: "Quality",
    description: "Unwavering commitment to excellence in every aspect of our work."
  },
  {
    icon: <Award className="w-12 h-12 text-blue-600" />,
    title: "Excellence",
    description: "Commit to high standards in design,craftsmanship, and client service to deliver superior results."
  },
  {
    icon: <TrendingUp className="w-12 h-12 text-blue-600" />,
    title: "Sustainability",
    description: "Prioritize eco-friendly practices and materials to ensure that our projects contribute positively to the environment."
  },
  {
    icon: <ChevronRight className="w-12 h-12 text-blue-600" />,
    title: "Collaboration",
    description: "Foster a team-oriented culture that values input from all stakeholders to achieve the best possible outcomes."
  },
  {
    icon: <CheckCircle className="!w-12 !h-12 !text-blue-600" />,
    title: "Integrity",
    description: "Maintain transparency, honesty and ethical standards in all business practices and client interactions."
  }
];

const milestones = [
  {
    year: "2018",
    event: "Company Founded",
    description: "BuildFast Structural Solutions was established in Harare."
  },
  {
    year: "2020",
    event: "First Major Project",
    description: "Completed our first commercial building project in the CBD."
  },
  {
    year: "2022",
    event: "Expansion",
    description: "Opened additional office and expanded services to include engineering."
  },
  {
    year: "2023",
    event: "Industry Recognition",
    description: "Received multiple awards for architectural excellence and innovation."
  }
];

export const About = ({title}) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <main className="min-h-screen bg-white">
      {/* Hero Section */}
      <section
        className="relative h-[80vh] flex items-center justify-center bg-gradient-to-br from-blue-800 to-blue-900 overflow-hidden"
      >
        {/* Decorative Elements */}
        <div className="absolute inset-0">
          <div className="absolute top-0 left-0 w-96 h-96 bg-yellow-400/20 rounded-full filter blur-3xl -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 right-0 w-96 h-96 bg-white/10 rounded-full filter blur-3xl translate-x-1/2 translate-y-1/2"></div>
        </div>

        {/* Grid Pattern Overlay */}
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[length:40px_40px]" />

        {/* Content */}
        <div className="relative z-10 max-w-7xl mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="flex flex-col items-center gap-6"
          >
            {/* Decorative Line */}
            <div className="w-20 h-1 bg-yellow-400 rounded-full mb-2"></div>
            
            {/* Main Title */}
            <motion.h1
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-4xl md:text-6xl lg:text-7xl font-bold text-white mb-6"
            >
              Building Dreams,{' '}
              <span className="text-yellow-400">Creating Future</span>
            </motion.h1>

            {/* Subtitle */}
            <motion.p
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              className="text-xl md:text-2xl text-gray-200 max-w-3xl mx-auto mb-8"
            >
              Excellence in Architecture & Engineering Since 2018
            </motion.p>

            {/* Stats Row */}
            <motion.div
              initial={{ y: 40, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.6 }}
              className="grid grid-cols-2 md:grid-cols-4 gap-8 md:gap-16 mt-8"
            >
              {[
                { number: "20+", label: "Years Experience" },
                { number: "500+", label: "Projects Completed" },
                { number: "50+", label: "Team Members" },
                { number: "100%", label: "Client Satisfaction" }
              ].map((stat, index) => (
                <motion.div
                  key={index}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.8 + index * 0.1 }}
                  className="text-center"
                >
                  <div className="text-3xl md:text-4xl font-bold text-yellow-400 mb-2">
                    {stat.number}
                  </div>
                  <div className="text-sm md:text-base text-gray-300">
                    {stat.label}
                  </div>
                </motion.div>
              ))}
            </motion.div>

            {/* CTA Buttons */}
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 1.2 }}
              className="flex flex-col sm:flex-row gap-4 mt-12"
            >
              <Link
                to="/contact"
                className="px-8 py-4 bg-yellow-400 text-blue-800 font-semibold rounded-xl 
                         hover:bg-yellow-300 transition-colors duration-300 
                         flex items-center justify-center gap-2 group"
              >
                Get Started
                <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              <Link
                to="/projects"
                className="px-8 py-4 bg-white/10 text-white font-semibold rounded-xl 
                         hover:bg-white/20 transition-colors duration-300 
                         flex items-center justify-center gap-2 group"
              >
                View Projects
                <ChevronRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </motion.div>
          </motion.div>
        </div>

        {/* Bottom Gradient Overlay */}
        <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-white to-transparent"></div>
      </section>

      {/* Company Overview Section */}
      <section className="py-16 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
            >
              <h2 className="text-3xl font-bold text-blue-800 mb-6">Our Story</h2>
              <div className="h-1 w-20 bg-yellow-400 mb-6"></div>
              <p className="text-gray-600 mb-6">
                With over 5 years of experience in architectural excellence, we've been transforming spaces and creating innovative solutions that inspire.
              </p>
              <div className="space-y-4">
                {[
                  "Innovative design solutions",
                  "Comprehensive project management",
                  "Sustainable building practices",
                  "Award-winning architectural team"
                ].map((item, index) => (
                  <motion.div 
                    key={index}
                    initial={{ x: -20, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ delay: index * 0.1 }}
                    className="flex items-center gap-3"
                  >
                    <CheckCircle className="w-5 h-5 text-yellow-400" />
                    <span className="text-gray-700">{item}</span>
                  </motion.div>
                ))}
              </div>
            </motion.div>
            <motion.div
              initial={{ x: 50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="grid grid-cols-2 gap-4"
            >
              <img src="https://images.pexels.com/photos/225502/pexels-photo-225502.jpeg?auto=compress&cs=tinysrgb&w=600" 
                   alt="Company office" 
                   className="rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300" />
              <img src="https://images.pexels.com/photos/5611713/pexels-photo-5611713.jpeg?auto=compress&cs=tinysrgb&w=600" 
                   alt="Project in progress" 
                   className="rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300" />
              <img src="https://images.pexels.com/photos/224924/pexels-photo-224924.jpeg?auto=compress&cs=tinysrgb&w=600" 
                   alt="Completed building" 
                   className="rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300" />
              <img src="https://images.pexels.com/photos/271667/pexels-photo-271667.jpeg?auto=compress&cs=tinysrgb&w=600" 
                   alt="Design team at work" 
                   className="rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300" />
            </motion.div>
          </div>
        </div>
      </section>

      {/* Values Section */}
      <section className="py-16 bg-gradient-to-br from-gray-50 to-white relative overflow-hidden">
        <div className="absolute inset-0 opacity-10">
          <div className="absolute top-0 left-0 w-96 h-96 bg-blue-800 rounded-full filter blur-3xl -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 right-0 w-96 h-96 bg-yellow-400 rounded-full filter blur-3xl translate-x-1/2 translate-y-1/2"></div>
        </div>
        
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <div className="text-center mb-16">
            <motion.span 
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              className="text-blue-800 font-semibold mb-4 flex items-center justify-center gap-2"
            >
              <div className="h-1 w-12 bg-yellow-400"></div>
              Our Values
              <div className="h-1 w-12 bg-yellow-400"></div>
            </motion.span>
            <motion.h2 
              initial={{ y: -20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
              className="text-4xl font-bold text-blue-800 mb-6"
            >
              What Drives Us Forward
            </motion.h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {values.map((value, index) => (
              <motion.div
                key={index}
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <div className="bg-blue-50 w-16 h-16 rounded-xl flex items-center justify-center mb-6">
                  {value.icon}
                </div>
                <h3 className="text-xl font-bold text-blue-800 mb-4">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Milestones Section */}
      <section className="py-16 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <motion.span 
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              className="text-blue-800 font-semibold mb-4 flex items-center justify-center gap-2"
            >
              <div className="h-1 w-12 bg-yellow-400"></div>
              Our Journey
              <div className="h-1 w-12 bg-yellow-400"></div>
            </motion.span>
            <motion.h2 
              initial={{ y: -20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
              className="text-4xl font-bold text-blue-800 mb-6"
            >
              Key Milestones
            </motion.h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {milestones.map((milestone, index) => (
              <motion.div
                key={index}
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="relative bg-white p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <div className="absolute -top-4 left-6 bg-yellow-400 text-blue-800 px-4 py-2 rounded-full font-bold">
                  {milestone.year}
                </div>
                <h3 className="text-xl font-bold text-blue-800 mt-4 mb-3">{milestone.event}</h3>
                <p className="text-gray-600">{milestone.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-16 bg-gradient-to-br from-blue-800 to-blue-900 relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute top-0 left-0 w-96 h-96 bg-yellow-400/20 rounded-full filter blur-3xl -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 right-0 w-96 h-96 bg-white/10 rounded-full filter blur-3xl translate-x-1/2 translate-y-1/2"></div>
        </div>
        
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              { number: "20+", label: "Years Experience" },
              { number: "500+", label: "Projects Completed" },
              { number: "50+", label: "Team Members" },
              { number: "100%", label: "Client Satisfaction" }
            ].map((stat, index) => (
              <motion.div
                key={index}
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: index * 0.1 }}
                className="text-center"
              >
                <h3 className="text-4xl font-bold text-white mb-2">{stat.number}</h3>
                <p className="text-gray-200">{stat.label}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

// Add this CSS to your global stylesheet or in a <style> tag in your HTML
const globalStyles = `
  html {
    scroll-behavior: smooth;
  }
`;

// Inject the global styles into the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = globalStyles;
document.head.appendChild(styleSheet);
