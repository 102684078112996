import React from 'react';

export const PrivacyPolicy = () => {
  return (
    <section className="bg-gray-50 py-12 px-6 md:px-12">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-8">
        <h1 className="text-3xl font-bold text-center mb-6">
          Privacy Policy
        </h1>
        <p className="text-lg text-gray-700 mb-6">
          Welcome to our digital sketchpad, where architecture meets innovation and privacy is treated with the utmost care—kind of like a delicate blueprint! We understand that in building not just great structures but also great relationships, trust is the foundation. That’s why we're committed to protecting your personal information as zealously as we protect our design secrets (well, almost).
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
          Collection and Use of Your Information
        </h2>
        <p className="text-gray-700 mb-6">
          In our quest to transform visions into reality, we may need to gather some of your personal details, like your name and email address. Think of it as the digital equivalent of setting the cornerstone in place. But fear not! We assure you that this information will be used solely to enhance your experience with us, keep you informed about our latest projects, and occasionally invite you to sneak peeks at future masterpieces.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
          Security Measures
        </h2>
        <p className="text-gray-700 mb-6">
          We’ve designed stringent security measures to keep your data safer than a jewel set in concrete. Our robust data protection practices ensure that only authorized personnel have access to what you've entrusted us with; it's akin to having an elite team of architects keeping an eye on a cherished construction site.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
          Sharing Your Information
        </h2>
        <p className="text-gray-700 mb-6">
          Our approach to sharing your data is as minimal as it is agile—we're basically the ninjas of non-disclosure. We pledge never to sell or lease your info to third parties for marketing purposes without receiving a clear go-ahead from you. Any sharing we do—as rare as spotting gravity-defying staircases—is either required by law or necessary for service delivery, and always approached with transparency.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
          Updates to This Policy
        </h2>
        <p className="text-gray-700 mb-6">
          Just like any architectural marvel, our Privacy Policy might undergo revisions from time to time. Whenever modifications occur, rest assured we'll update our site so you're always ahead of the curve—rather fitting for a cutting-edge firm like ours!
        </p>

        <p className="text-lg text-gray-800 font-semibold mt-8 mb-6">
          Thank you for being part of our architectural journey!
        </p>
        <p className="text-gray-700 mb-6">
          For more details about privacy or any policy-related inquiries, feel free to reach out to us at <a href="mailto:info@example.com" className="text-blue-600 hover:underline">info@example.com</a>.
        </p>
      </div>
    </section>
  );
};

