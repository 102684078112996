import React from 'react';

export const SafetyCommitment = () => {
  return (
    <section className="bg-gray-50 py-12 px-6 md:px-12">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-8">
        <h1 className="text-3xl font-bold text-center mb-6">
          Our Safety Commitment
        </h1>
        <p className="text-lg text-gray-700 mb-6">
          At our core, safety is as much a priority as creating innovative architectural designs. We are committed to ensuring a safe environment for our team, clients, and partners in all our operations.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
          Workplace Safety
        </h2>
        <p className="text-gray-700 mb-6">
          We adhere to the highest safety standards in all our projects. From design to execution, we work with expert teams to ensure that every detail is in line with industry best practices. Safety briefings, regular inspections, and continuous education are a few of the methods we use to ensure a safe working environment.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
          Client and Partner Safety
        </h2>
        <p className="text-gray-700 mb-6">
          We value the safety of our clients and partners just as much as that of our team. We follow stringent protocols during site visits, meetings, and collaborations to protect everyone involved. This includes proper safety gear, secure work areas, and adherence to local safety regulations.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
          Data Security
        </h2>
        <p className="text-gray-700 mb-6">
          In today’s digital age, safety extends beyond physical spaces. We are committed to safeguarding your personal information with top-tier data protection protocols, ensuring that all client and project data remains secure.
        </p>

        <p className="text-lg text-gray-800 font-semibold mt-8 mb-6">
          Safety is the cornerstone of everything we build.
        </p>
        <p className="text-gray-700 mb-6">
          For more information about our safety policies or to discuss any safety-related concerns, feel free to contact us at <a href="mailto:safety@example.com" className="text-blue-600 hover:underline">safety@example.com</a>.
        </p>
      </div>
    </section>
  );
};

