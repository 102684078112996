import React from 'react';
import { motion } from 'framer-motion';

export const CareersPage = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      {/* Hero Section */}
      <motion.div
        className="bg-yellow-500 w-full py-16 text-center text-white"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <h1 className="text-4xl font-bold mb-4">Join Our Team</h1>
        <p className="text-xl">Build your future with us!</p>
      </motion.div>

      {/* Job Listings Section */}
      <motion.div
        className="my-16 text-center"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, delay: 0.5 }}
      >
        <h2 className="text-3xl font-semibold mb-8">Current Job Openings</h2>

        {/* No Jobs Placeholder */}
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <motion.p
            className="text-lg text-gray-600"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}
          >
            Sorry, there are no job openings available at the moment.
          </motion.p>
        </div>
      </motion.div>

      {/* Call to Action Section */}
      <motion.div
        className="text-center my-12"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        <p className="text-xl mb-4">
          Interested in joining us? Check back soon or send your resume!
        </p>
        {/* <motion.button
          className="bg-blue-500 text-white py-3 px-6 rounded-full font-semibold hover:bg-blue-600"
          whileHover={{ scale: 1.1 }}
          transition={{ type: 'spring', stiffness: 300 }}
        >
          Contact Us
        </motion.button> */}
      </motion.div>
    </div>
  );
};


