import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { ChevronRight, Phone, Mail, MapPin, Send, CheckCircle, Building2, Ruler, Building, PencilRuler, ClipboardList, Target, Shield, Clock, Users, Calendar } from 'lucide-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import bgimage from '../Assets/back.png';
import bgimage2 from '../Assets/back2.png';
import bgimage3 from '../Assets/back3.png';
import about from '../Assets/about.png';
import project1 from "../Assets/project1.png";
import magolis from "../Assets/margolis.png"
import vainona from "../Assets/Vainona.png";
import './styles/Home.css';
import { Helmet } from 'react-helmet';

export const Home = ({title}) => {
  const [currentBg, setCurrentBg] = useState(bgimage);
  const [currentBgIndex, setCurrentBgIndex] = useState(0);
  const navigate = useNavigate();
  const [formStatus, setFormStatus] = useState('');

  const handleProjectClick = () => {
  navigate('/projects');
  };

  useEffect(() => {
    document.title = title;
  }, [title]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    
    try {
      const response = await fetch("https://formspree.io/f/myzypawe", {
        method: "POST",
        body: data,
        headers: {
          'Accept': 'application/json'
        }
      });
      
      if (response.ok) {
        setFormStatus("Thanks for your submission!");
        form.reset();
      } else {
        setFormStatus("Oops! There was a problem submitting your form");
      }
    } catch (error) {
      setFormStatus("Oops! There was a problem submitting your form");
    }
  };

  const bgImages = useMemo(() => [bgimage, bgimage2, bgimage3], []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBgIndex((prevIndex) => (prevIndex + 1) % bgImages.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [bgImages.length]);

  useEffect(() => {
    setCurrentBg(bgImages[currentBgIndex]);
  }, [currentBgIndex, bgImages]);

  const handleServices = () => {
    navigate("/services/services");
  };

  const projects = [
    {
      title: "MANSION FOR MR DHIHWA IN BORROWDALE",
      description: "An opulent mansion featuring cutting-edge architectural design and luxurious interiors, crafted to reflect elegance and comfort in the prestigious Borrowdale neighborhood.",
      image: project1,
    },
    {
      title: "STEPHEN MARGOLIS' SHOPPING MALL",
      description: "A dynamic and expansive shopping mall offering a blend of modern retail spaces, entertainment hubs, and sustainable design to create a top-tier shopping destination.",
      image: magolis,
    },
    {
      title: "VAINONA CLUSTERS",
      description: "A thoughtfully designed residential development offering a blend of contemporary homes and community-driven living, transforming Vainona into a vibrant, harmonious neighborhood.",
      image: vainona,
    },
  ];

  const handleClick = () => {
    navigate('/about_build_fast');
  };

  const handleServiceClick = (service) => {
    navigate(`/services/${service.toLowerCase().replace(' ', '-')}`);
  };

  const services = [
    {
      title: "Architecture",
      description: "We craft innovative and functional designs that harmonize with their surroundings and reflect our clients' visions.",
      image: "https://images.pexels.com/photos/1109541/pexels-photo-1109541.jpeg?auto=compress&cs=tinysrgb&w=600",
      icon: Building2
    },
    {
      title: "Engineering",
      description: "Our engineering team integrates structural, mechanical, and electrical systems to ensure seamless functionality and safety.",
      image: "https://images.pexels.com/photos/33192/paddle-wheel-bucket-wheel-excavators-brown-coal-open-pit-mining.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      icon: Ruler
    },
    {
      title: "Town Planning",
      description: "We offer expert town planning services to help shape the development of communities and urban spaces.",
      image: "https://images.pexels.com/photos/1131863/pexels-photo-1131863.jpeg?auto=compress&cs=tinysrgb&w=600",
      icon: Building
    },
    {
      title: "Interior Design",
      description: "Our interior design team creates spaces that are not only beautiful but also functional, enhancing the quality of life.",
      image: "https://images.pexels.com/photos/276267/pexels-photo-276267.jpeg?auto=compress&cs=tinysrgb&w=600",
      icon: PencilRuler
    },
    {
      title: "Project Management",
      description: "We manage all aspects of construction projects to ensure they are completed on time, within budget, and to the highest standards.",
      image: "https://images.pexels.com/photos/7376/startup-photos.jpg?auto=compress&cs=tinysrgb&w=600",
      icon: ClipboardList
    }
  ];

  const pricingTiers = [
    {
      size: "200-300SQM",
      price: "175",
      features: [
        "Main House Plan",
        "Durawall (Boundary) Wall Plan",
        "Bonus: 3D Images"
      ]
    },
    {
      size: "350-500SQM",
      price: "315",
      features: [
        "Main House Plan",
        "Cottage Plan",
        "Durawall (Boundary) Wall Plan",
        "Approval Assistance",
        "Bonus: 3D Images"
      ]
    },
    {
      size: "500-1000SQM",
      price: "420",
      features: [
        "Main House Plan",
        "Cottage Plan",
        "Durawall (Boundary) Wall Plan",
        "Approval Assistance",
        "Bonus: 3D Images"
      ]
    },
    {
      size: "1000+SQM Single",
      price: "610",
      features: [
        "Single Storey Plan",
        "Cottage Plan",
        "Durawall (Boundary) Wall Plan",
        "Approval Assistance",
        "Bonus: 3D Images"
      ]
    },
    {
      size: "1000+SQM Double",
      price: "965",
      features: [
        "Double Storey Plan",
        "Cottage Plan",
        "Boundary Wall Plan",
        "Engineering Drawings",
        "Approval Assistance",
        "Bonus: 3D Images"
      ]
    }
  ];

  return (
    <main className="overflow-x-hidden gap-6">
      <Helmet>
        <meta name="google-site-verification" content="q2KMOxqP0WRZnd8BDIXv-m18BP7E8GapB0_6ZOX9pZM"  />
      </Helmet>
      {/* Background Image and Hero Section */}
      <section className="relative h-screen overflow-hidden">
        <AnimatePresence initial={false}>
          <motion.div
            key={currentBgIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.9 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className="absolute inset-0 bg-cover bg-center"
            style={{ 
              backgroundImage: `url(${currentBg})`,
              backgroundBlendMode: 'overlay'
            }}
          />
        </AnimatePresence>
        <div className="relative h-full flex items-center justify-center z-10">
          <div className="text-center text-white max-w-4xl px-4">
            <motion.h1
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="text-4xl md:text-6xl font-bold mb-6"
            >
              Buildfast Structural Solutions
            </motion.h1>
            <motion.p
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-2xl md:text-3xl mb-12 text-yellow-400"
            >
              YOU DREAM IT, WE DESIGN IT, WE BUILD IT!
            </motion.p>
            <motion.button
              onClick={handleServices}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-yellow-400 text-blue-800 font-bold py-4 px-8 rounded-lg text-lg hover:bg-yellow-300 transition-all duration-300 shadow-lg"
            >
              Explore Our Services
              <ChevronRight className="inline-block ml-2" size={20} />
            </motion.button>
          </div>
        </div>
      </section>

      {/* About Us Section */}
      <section className="py-24 bg-gradient-to-br from-white to-gray-50 relative overflow-hidden">
        {/* Background Decorations */}
        <div className="absolute inset-0 opacity-10">
          <div className="absolute top-0 right-0 w-96 h-96 bg-blue-800 rounded-full filter blur-3xl translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 left-0 w-96 h-96 bg-yellow-400 rounded-full filter blur-3xl -translate-x-1/2 translate-y-1/2"></div>
        </div>

        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <div className="flex flex-col lg:flex-row items-center gap-12">
            {/* Image Column */}
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="lg:w-1/2"
            >
              <div className="relative">
                {/* Main Image */}
                <div className="relative z-20 rounded-2xl overflow-hidden shadow-2xl">
                  <img 
                    src={about} 
                    alt="About BuildFast" 
                    className="w-full h-auto object-cover transform hover:scale-105 transition-transform duration-500"
                  />
                  {/* Overlay gradient */}
                  <div className="absolute inset-0 bg-gradient-to-t from-blue-800/40 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300"></div>
                </div>
                
                {/* Decorative Elements */}
                <div className="absolute -top-6 -left-6 w-24 h-24 bg-yellow-400 rounded-xl -z-10"></div>
                <div className="absolute -bottom-6 -right-6 w-32 h-32 bg-blue-800 rounded-xl -z-10"></div>
              </div>

              {/* Stats */}
              <div className="grid grid-cols-2 gap-4 mt-8">
                <motion.div 
                  whileHover={{ y: -5 }}
                  className="bg-white p-4 rounded-xl shadow-lg"
                >
                  <h4 className="text-4xl font-bold text-blue-800">10+</h4>
                  <p className="text-gray-600">Years Experience</p>
                </motion.div>
                <motion.div 
                  whileHover={{ y: -5 }}
                  className="bg-white p-4 rounded-xl shadow-lg"
                >
                  <h4 className="text-4xl font-bold text-blue-800">200+</h4>
                  <p className="text-gray-600">Projects Completed</p>
                </motion.div>
              </div>
            </motion.div>

            {/* Content Column */}
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="lg:w-1/2 space-y-6"
            >
              <div className="space-y-4">
                <div className="flex items-center gap-2">
                  <div className="h-1 w-12 bg-yellow-400"></div>
                  <span className="text-blue-800 font-semibold">About Us</span>
                </div>
                <h2 className="text-4xl font-bold text-gray-900">
                  Building Dreams Into Reality
                </h2>
              </div>

              <div className="space-y-4 text-gray-600">
                <p className="text-lg">
                  At BuildFast Structural Solutions, we are dedicated to transforming visions into reality through innovative and sustainable design. Our experienced team is committed to delivering exceptional results that inspire and enhance the human experience.
                </p>
                <p className="text-lg">
                  With a team of visionary architects and design professionals, we specialize in creating dynamic environments that blend functionality with aesthetic appeal. Our approach is rooted in a deep understanding of our clients' needs and aspirations.
                </p>
              </div>

              {/* Key Features */}
              <div className="grid grid-cols-2 gap-4 mt-8">
                {[
                  { icon: Target, title: "Vision", text: "Clear project direction" },
                  { icon: Shield, title: "Quality", text: "Premium materials" },
                  { icon: Clock, title: "Timely", text: "On-time delivery" },
                  { icon: Users, title: "Team", text: "Expert professionals" }
                ].map((feature, index) => (
                  <motion.div
                    key={feature.title}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="flex items-start gap-3 p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
                  >
                    <div className="p-2 bg-blue-800/10 rounded-lg">
                      <feature.icon className="w-6 h-6 text-blue-800" />
                    </div>
                    <div>
                      <h4 className="font-semibold text-gray-900">{feature.title}</h4>
                      <p className="text-sm text-gray-600">{feature.text}</p>
                    </div>
                  </motion.div>
                ))}
              </div>

              {/* CTA Button */}
              <motion.button
                onClick={handleClick}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-8 bg-blue-800 text-white font-bold py-4 px-8 rounded-xl hover:bg-blue-700 transition-colors duration-300 flex items-center gap-2 group"
              >
                Learn More About Us
                <ChevronRight className="group-hover:translate-x-1 transition-transform" />
              </motion.button>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20 bg-white relative overflow-hidden">
        {/* Background decoration */}
        <div className="absolute inset-0 opacity-10">
          <div className="absolute top-0 left-0 w-96 h-96 bg-yellow-400 rounded-full filter blur-3xl -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 right-0 w-96 h-96 bg-blue-400 rounded-full filter blur-3xl translate-x-1/2 translate-y-1/2"></div>
        </div>

        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="max-w-7xl mx-auto px-4 relative z-10"
        >
          <div className="text-center mb-16">
            <motion.h2 
              initial={{ y: -20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
              className="text-4xl md:text-5xl font-bold text-blue-800 mb-4"
            >
              Our Services
            </motion.h2>
            <motion.div 
              initial={{ scaleX: 0 }}
              whileInView={{ scaleX: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="w-24 h-1 bg-yellow-400 mx-auto"
            ></motion.div>
          </div>

          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={30}
            slidesPerView={1}
            breakpoints={{
              640: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 5000 }}
            loop={true}
            className="services-swiper"
          >
            {services.map((service, index) => (
              <SwiperSlide key={index}>
                <motion.div 
                  whileHover={{ y: -10 }}
                  transition={{ duration: 0.3 }}
                  className="bg-white rounded-2xl shadow-2xl overflow-hidden group cursor-pointer h-full"
                  onClick={() => handleServiceClick(service.title)}
                >
                  <div className="relative h-48 overflow-hidden">
                    <img 
                      className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110" 
                      src={service.image} 
                      alt={service.title} 
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-blue-800/80 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                  </div>
                  
                  <div className="p-6 relative">
                    <div className="absolute -top-8 right-6 w-16 h-16 bg-yellow-400 rounded-xl shadow-lg flex items-center justify-center transform -rotate-12 group-hover:rotate-0 transition-transform duration-300">
                      <service.icon className="w-8 h-8 text-blue-800" />
                    </div>
                    
                    <h3 className="text-xl font-bold text-blue-800 mb-3 group-hover:text-yellow-400 transition-colors">
                      {service.title}
                    </h3>
                    <p className="text-gray-600 mb-4 line-clamp-3">
                      {service.description}
                    </p>
                    <div className="flex items-center text-yellow-400 font-semibold group-hover:text-blue-800 transition-colors">
                      Learn More
                      <motion.div
                        className="ml-2"
                        whileHover={{ x: 5 }}
                        transition={{ duration: 0.2 }}
                      >
                        <ChevronRight size={16} />
                      </motion.div>
                    </div>
                  </div>
                </motion.div>
              </SwiperSlide>
            ))}
          </Swiper>
        </motion.div>
      </section>

      {/* Projects Section */}
      <section className="py-24 bg-gradient-to-br from-gray-50 to-white relative overflow-hidden">
        {/* Background Decorations */}
        <div className="absolute inset-0 opacity-10">
          <div className="absolute top-0 left-0 w-96 h-96 bg-blue-800 rounded-full filter blur-3xl -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 right-0 w-96 h-96 bg-yellow-400 rounded-full filter blur-3xl translate-x-1/2 translate-y-1/2"></div>
        </div>

        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="max-w-7xl mx-auto px-4 relative z-10"
        >
          {/* Section Header */}
          <div className="text-center mb-16">
            <motion.span 
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              className="text-blue-800 font-semibold mb-4 flex items-center justify-center gap-2"
            >
              <div className="h-1 w-12 bg-yellow-400"></div>
              Our Portfolio
              <div className="h-1 w-12 bg-yellow-400"></div>
            </motion.span>
            <motion.h2 
              initial={{ y: -20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.1 }}
              className="text-4xl md:text-5xl font-bold text-gray-900 mb-6"
            >
              Recent Projects
            </motion.h2>
            <motion.p
              initial={{ y: 20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="max-w-2xl mx-auto text-gray-600"
            >
              Explore our latest architectural masterpieces and innovative designs that showcase our commitment to excellence.
            </motion.p>
          </div>

          {/* Projects Slider */}
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={30}
            slidesPerView={1}
            breakpoints={{
              640: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 5000 }}
            loop={true}
            className="projects-swiper"
          >
            {projects.map((project, index) => (
              <SwiperSlide key={index}>
                <motion.div 
                  whileHover={{ y: -10 }}
                  transition={{ duration: 0.3 }}
                  className="bg-white rounded-2xl shadow-xl overflow-hidden group cursor-pointer"
                  onClick={handleProjectClick}
                >
                  {/* Project Image */}
                  <div className="relative h-64 overflow-hidden">
                    <img 
                      src={project.image} 
                      alt={project.title}
                      className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-blue-800/90 via-blue-800/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <div className="absolute bottom-0 left-0 right-0 p-6 transform translate-y-6 group-hover:translate-y-0 transition-transform duration-300">
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          className="bg-yellow-400 text-blue-800 font-semibold py-2 px-4 rounded-lg flex items-center gap-2"
                        >
                          View Details
                          <ChevronRight size={16} />
                        </motion.button>
                      </div>
                    </div>
                  </div>

                  {/* Project Info */}
                  <div className="p-6">
                    <div className="mb-4">
                      <h3 className="text-xl font-bold text-blue-800 mb-2 group-hover:text-yellow-400 transition-colors">
                        {project.title}
                      </h3>
                      <div className="h-0.5 w-12 bg-yellow-400 group-hover:w-full transition-all duration-300"></div>
                    </div>
                    <p className="text-gray-600 line-clamp-3">
                      {project.description}
                    </p>
                    
                    {/* Project Stats */}
                    <div className="mt-6 pt-6 border-t border-gray-100">
                      <div className="flex justify-between text-sm">
                        <div className="flex items-center gap-2">
                          <Calendar className="w-4 h-4 text-blue-800" />
                          <span className="text-gray-600">2023</span>
                        </div>
                        <div className="flex items-center gap-2">
                          <MapPin className="w-4 h-4 text-blue-800" />
                          <span className="text-gray-600">Harare, Zimbabwe</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </SwiperSlide>
            ))}
          </Swiper>

          {/* View All Projects Button */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className="text-center mt-12"
          >
            <motion.button
              onClick={handleProjectClick}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-blue-800 text-white font-bold py-4 px-8 rounded-xl hover:bg-blue-700 transition-colors duration-300 flex items-center gap-2 mx-auto group"
            >
              View All Projects
              <ChevronRight className="group-hover:translate-x-1 transition-transform" />
            </motion.button>
          </motion.div>
        </motion.div>
      </section>

      {/* Products and Prices Section */}
      <section className="py-16 bg-gradient-to-b from-white to-gray-50">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="max-w-7xl mx-auto px-4"
        >
          <h2 className="text-4xl font-bold mb-12 text-center text-blue-800">Our Products and Prices</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {pricingTiers.map((tier, index) => (
              <motion.div
                key={tier.size}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white p-6 rounded-lg shadow-xl relative overflow-hidden border-2 border-gray-100 hover:border-yellow-400 transition-all duration-300"
              >
                <div className="absolute -right-12 top-6 rotate-45 bg-blue-800 text-white px-12 py-1 text-sm">
                  ${tier.price}
                </div>
                <h3 className="text-xl font-bold mb-4 text-blue-800">{tier.size}</h3>
                <ul className="space-y-3 mb-6">
                  {tier.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-start">
                      <CheckCircle className="w-5 h-5 text-yellow-400 mr-2 flex-shrink-0" />
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>
                <motion.button 
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="w-full bg-blue-800 text-white font-bold py-3 px-4 rounded-lg hover:bg-blue-700 transition-all duration-300"
                >
                  Get Started
                </motion.button>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </section>

      {/* Contact Us Section */}
      <section className="py-16 px-4 sm:px-6 lg:px-8 bg-gradient-to-br from-gray-100 to-white">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="max-w-7xl mx-auto h-full"
        >
          <h2 className="text-4xl font-bold mb-12 text-center text-gray-900">
            Get In Touch
          </h2>
          <div className="flex flex-col lg:flex-row gap-8">
            {/* Contact Form */}
            <motion.div 
              className="flex-1 bg-white rounded-lg shadow-xl p-8 border border-gray-200"
              whileHover={{ boxShadow: "0 8px 30px rgba(0,0,0,0.12)" }}
              transition={{ duration: 0.3 }}
            >

              <form id="contact-form" onSubmit={handleSubmit} className="space-y-6">
                <div className="relative">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="peer w-full px-3 py-3 border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-500 transition-colors"
                    placeholder="Your Name"
                    required
                  />
                  <label 
                    htmlFor="name" 
                    className="absolute left-3 -top-5 text-sm text-gray-600 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 peer-focus:-top-5 peer-focus:text-sm peer-focus:text-blue-500"
                  >
                    Your Name
                  </label>
                </div>
                <div className="relative">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="peer w-full px-3 py-3 border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-500 transition-colors"
                    placeholder="Your Email"
                    required
                  />
                  <label 
                    htmlFor="email" 
                    className="absolute left-3 -top-5 text-sm text-gray-600 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 peer-focus:-top-5 peer-focus:text-sm peer-focus:text-blue-500"
                  >
                    Your Email
                  </label>
                </div>
                <div className="relative">
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    className="peer w-full px-3 py-3 border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-500 transition-colors"
                    placeholder="WhatsApp/Phone Number"
                    required
                  />
                  <label 
                    htmlFor="phone" 
                    className="absolute left-3 -top-5 text-sm text-gray-600 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 peer-focus:-top-5 peer-focus:text-sm peer-focus:text-blue-500"
                  >
                    WhatsApp/Phone Number
                  </label>
                </div>
                <div className="relative">
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    className="peer w-full px-3 py-3 border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-500 transition-colors resize-none"
                    placeholder="Your Message"
                    required
                  ></textarea>
                  <label 
                    htmlFor="message" 
                    className="absolute left-3 -top-5 text-sm text-gray-600 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 peer-focus:-top-5 peer-focus:text-sm peer-focus:text-blue-500"
                  >
                    Your Message
                  </label>
                </div>
                <motion.button 
                  type="submit" 
                  className="w-full bg-gradient-to-r from-cyan-500 to-blue-500 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out flex items-center justify-center group hover:from-cyan-600 hover:to-blue-600"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Send className="mr-2 transition-transform duration-300 group-hover:translate-x-1" size={20} />
                  Send Message
                </motion.button>
              </form>
              {formStatus && (
                <motion.p 
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="mt-4 text-green-600 text-center"
                >
                  {formStatus}
                </motion.p>
              )}
            </motion.div>

            {/* Contact Information */}
            <motion.div 
              className="flex-1 bg-white rounded-lg shadow-xl p-8 border border-gray-200"
              whileHover={{ boxShadow: "0 8px 30px rgba(0,0,0,0.12)" }}
              transition={{ duration: 0.3 }}
            >
              <div className="space-y-6">
                <div className="flex items-center space-x-4 p-4 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg">
                  <div className="p-3 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full text-white">
                    <Mail size={24} />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Email</p>
                    <a href="mailto:info@buildfast.co.zw" className="text-gray-900 hover:text-blue-500 transition-colors">
                      info@buildfast.co.zw
                    </a>
                  </div>
                </div>
                <div className="flex items-center space-x-4 p-4 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg">
                  <div className="p-3 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full text-white">
                    <Phone size={24} />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Phone</p>
                    <a href="tel:+263776347735" className="text-gray-900 hover:text-blue-500 transition-colors">
                      +263 776 347 735
                    </a>
                  </div>
                </div>
                <div className="flex items-center space-x-4 p-4 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg">
                  <div className="p-3 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full text-white">
                    <MapPin size={24} />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Address</p>
                    <p className="text-gray-900">Margoliz Plaza Cnr Harare st & Speke Ave, Harare</p>
                  </div>
                </div>
                <div className="mt-8 p-4 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg">
                  <h4 className="text-xl font-semibold mb-4 text-gray-900">Office Hours</h4>
                  <ul className="space-y-2 text-gray-600">
                    <li className="flex items-center">
                      <span className="w-4 h-4 bg-green-500 rounded-full mr-2"></span>
                      Monday - Friday: 9:00 AM - 5:00 PM
                    </li>
                    <li className="flex items-center">
                      <span className="w-4 h-4 bg-yellow-500 rounded-full mr-2"></span>
                      Saturday: 10:00 AM - 2:00 PM
                    </li>
                    <li className="flex items-center">
                      <span className="w-4 h-4 bg-red-500 rounded-full mr-2"></span>
                      Sunday: Closed
                    </li>
                  </ul>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </section>
    </main>
  );
};

export default Home;
