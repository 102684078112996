/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ChevronRight, Briefcase, Users, Clock, Linkedin, Twitter, Mail } from 'lucide-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import { storage } from '../components/config/firebase';
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import 'swiper/css';
import 'swiper/css/pagination';
import team from "../Assets/team.jpeg"
import eng from "../Assets/eng.jpg"
import work from "../Assets/work.jpg"
import quility from "../Assets/eng2.jpg"
import teamwork from "../Assets/team15.jpg"

const projectShowcase = [
  {
    title: "Innovative Design Solutions",
    description: "Our team collaboratively developing cutting-edge design concepts",
    image: work,
    category: "Design"
  },
  {
    title: "Precision Engineering",
    description: "Implementing complex technical solutions with precision",
    image: eng,
    category: "Engineering"
  },
  {
    title: "Quality Assurance",
    description: "Rigorous testing and verification processes in action",
    image: quility,
    category: "Quality Control"
  },
  {
    title: "Project Implementation",
    description: "Bringing designs to life with expert execution",
    image: teamwork,
    category: "Implementation"
  }
];

const stats = [
  { icon: Briefcase, value: '200+', label: 'Projects Completed' },
  { icon: Users, value: '50+', label: 'Team Members' },
  { icon: Clock, value: '5+', label: 'Years Experience' },
];

// Add team members data
const teamMembers = [
  {
    name: "John Smith",
    position: "Principal Architect",
    description: "Over 15 years of experience in architectural design and project management.",
    image: "/team/member1.jpg",
    socialLinks: [
      { icon: <Linkedin />, url: "#" },
      { icon: <Twitter />, url: "#" },
      { icon: <Mail />, url: "#" }
    ]
  },
  {
    name: "Sarah Johnson",
    position: "Senior Engineer",
    description: "Specializes in structural engineering with a focus on sustainable design.",
    image: "/team/member2.jpg",
    socialLinks: [
      { icon: <Linkedin />, url: "#" },
      { icon: <Twitter />, url: "#" },
      { icon: <Mail />, url: "#" }
    ]
  },
  // Add more team members as needed
];

export const TeamPage = ({ title = "Our Team" }) => {
  const [teamPhotos, setTeamPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = title;
    fetchTeamPhotos();
  }, [title]);

  const fetchTeamPhotos = async () => {
    try {
      const imagesRef = ref(storage, 'team-photos');
      const imagesList = await listAll(imagesRef);
      
      const urlPromises = imagesList.items.map(async (imageRef) => {
        const url = await getDownloadURL(imageRef);
        return {
          src: url,
          alt: `Team at work - ${imageRef.name}`
        };
      });

      const photos = await Promise.all(urlPromises);
      setTeamPhotos(photos);
    } catch (error) {
      console.error("Error fetching team photos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleButton = () => {
    navigate('./contact-us');
  };

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="text-2xl text-gray-600">Loading team photos...</div>
      </div>
    );
  }

  return (
    <main className="min-h-screen bg-white">
      {/* Hero Section */}
      <section className="relative py-24 bg-gradient-to-br from-blue-800 to-blue-900 overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute top-0 left-0 w-96 h-96 bg-yellow-400/20 rounded-full filter blur-3xl -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 right-0 w-96 h-96 bg-white/10 rounded-full filter blur-3xl translate-x-1/2 translate-y-1/2"></div>
        </div>

        <div className="relative max-w-7xl mx-auto px-4 text-center z-10">
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="flex flex-col items-center gap-6"
          >
            <div className="w-20 h-1 bg-yellow-400 rounded-full"></div>
            <motion.h1
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-4xl md:text-6xl font-bold text-white mb-6"
            >
              Meet Our <span className="text-yellow-400">Expert Team</span>
            </motion.h1>
            <motion.p
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              className="text-xl text-gray-200 max-w-3xl mx-auto"
            >
              Dedicated professionals committed to bringing your vision to life
            </motion.p>
          </motion.div>
        </div>
      </section>

      {/* Project Showcase Section */}
      <section className="py-24 bg-gradient-to-br from-gray-50 to-white relative overflow-hidden">
        <div className="absolute inset-0 opacity-10">
          <div className="absolute top-0 left-0 w-96 h-96 bg-blue-800 rounded-full filter blur-3xl -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 right-0 w-96 h-96 bg-yellow-400 rounded-full filter blur-3xl translate-x-1/2 translate-y-1/2"></div>
        </div>

        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <div className="text-center mb-16">
            <motion.span 
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              className="text-blue-800 font-semibold mb-4 flex items-center justify-center gap-2"
            >
              <div className="h-1 w-12 bg-yellow-400"></div>
              Our Work
              <div className="h-1 w-12 bg-yellow-400"></div>
            </motion.span>
            <h2 className="text-3xl font-bold text-blue-800 mb-12">Project Showcase</h2>
          </div>

          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={30}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{ delay: 5000 }}
            breakpoints={{
              640: { slidesPerView: 2 },
              1024: { slidesPerView: 3 }
            }}
            className="pb-12"
          >
            {projectShowcase.map((project, index) => (
              <SwiperSlide key={index}>
                <motion.div
                  initial={{ y: 50, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="bg-white rounded-xl overflow-hidden shadow-lg group"
                >
                  <div className="relative h-64 overflow-hidden">
                    <img
                      src={project.image}
                      alt={project.title}
                      className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-blue-900/50 to-transparent"></div>
                  </div>
                  <div className="p-6">
                    <h3 className="text-xl font-bold text-blue-800 mb-2">{project.title}</h3>
                    <p className="text-gray-600">{project.description}</p>
                    <span className="inline-block mt-4 text-yellow-600 font-semibold">{project.category}</span>
                  </div>
                </motion.div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-16 bg-gradient-to-br from-blue-800 to-blue-900 relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute top-0 left-0 w-96 h-96 bg-yellow-400/20 rounded-full filter blur-3xl -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 right-0 w-96 h-96 bg-white/10 rounded-full filter blur-3xl translate-x-1/2 translate-y-1/2"></div>
        </div>

        <div className="max-w-7xl mx-auto px-4 text-center relative z-10">
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="space-y-8"
          >
            <h2 className="text-4xl font-bold text-white">Ready to Start Your Project?</h2>
            <p className="text-xl text-gray-200 max-w-2xl mx-auto">
              Let our team help bring your vision to life. Contact us to discuss your needs.
            </p>
            <button
              onClick={handleButton}
              className="inline-flex items-center justify-center px-8 py-4 bg-yellow-400 text-blue-800 font-semibold rounded-xl 
                     hover:bg-yellow-300 transition-colors duration-300 gap-2 group"
            >
              Get In Touch
              <ChevronRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </button>
          </motion.div>
        </div>
      </section>
    </main>
  );
};