/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { MapPin, Phone, Mail, Users, Building } from 'lucide-react';

export const Contacts = ({title}) => {
  const [formStatus, setFormStatus] = useState(null);
  const contactPeople = [
    { name: 'John Smith', role: 'Project Director' },
    { name: 'Sarah Johnson', role: 'Client Relations Manager' },
    { name: 'Mike Brown', role: 'Technical Lead' }
  ];

  useEffect(() => {
    document.title = title;
  }, [title]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    
    try {
      const response = await fetch("https://formspree.io/f/myzypawe", {
        method: "POST",
        body: data,
        headers: {
          'Accept': 'application/json'
        }
      });
      
      if (response.ok) {
        setFormStatus({
          type: 'success',
          message: 'Thanks for your submission!'
        });
        form.reset();
      } else {
        setFormStatus({
          type: 'error',
          message: 'Oops! There was a problem submitting your form'
        });
      }
    } catch (error) {
      setFormStatus({
        type: 'error',
        message: 'Oops! There was a problem submitting your form'
      });
    }
  };

  return (
    <main className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-7xl mx-auto px-4 py-12 sm:py-16 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl sm:text-5xl font-bold bg-gradient-to-r from-primary via-secondary to-accent bg-clip-text text-transparent">
            Let's Connect
          </h1>
          <p className="mt-4 text-lg text-gray-600">
            We're here to help bring your vision to life
          </p>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Contact Form */}
          <motion.div 
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            className="lg:col-span-2 bg-white rounded-2xl shadow-xl p-8"
          >
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div className="relative">
                  <input
                    type="text"
                    name="name"
                    required
                    className="w-full px-4 py-3 bg-gray-50 border-2 border-gray-100 rounded-lg focus:outline-none focus:border-primary transition-colors"
                    placeholder="Your Name"
                  />
                </div>
                <div className="relative">
                  <input
                    type="email"
                    name="email"
                    required
                    className="w-full px-4 py-3 bg-gray-50 border-2 border-gray-100 rounded-lg focus:outline-none focus:border-primary transition-colors"
                    placeholder="Your Email"
                  />
                </div>
              </div>

              <div className="relative">
                <input
                  type="text"
                  name="subject"
                  required
                  className="w-full px-4 py-3 bg-gray-50 border-2 border-gray-100 rounded-lg focus:outline-none focus:border-primary transition-colors"
                  placeholder="Subject"
                />
              </div>

              <div className="relative">
                <textarea
                  name="message"
                  rows="5"
                  required
                  className="w-full px-4 py-3 bg-gray-50 border-2 border-gray-100 rounded-lg focus:outline-none focus:border-primary transition-colors resize-none"
                  placeholder="Your Message"
                ></textarea>
              </div>

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="w-full py-4 bg-gradient-to-r from-primary via-secondary to-accent text-white font-semibold rounded-lg shadow-lg hover:shadow-xl transition-all duration-300"
                type="submit"
              >
                Send Message
              </motion.button>

              {formStatus && (
                <motion.p 
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className={`text-center ${
                    formStatus.type === 'success' ? 'text-green-500' : 'text-red-500'
                  }`}
                >
                  {formStatus.message}
                </motion.p>
              )}
            </form>
          </motion.div>

          {/* Contact Info */}
          <motion.div 
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            className="space-y-6"
          >
            <div className="bg-white rounded-2xl shadow-xl p-6 hover:shadow-2xl transition-shadow duration-300">
              <div className="flex items-center space-x-4">
                <div className="p-3 bg-gradient-to-r from-primary to-secondary rounded-xl">
                  <Building className="w-6 h-6 text-white" />
                </div>
                <div>
                  <h3 className="font-semibold text-lg">Our Office</h3>
                  <p className="text-gray-600">Margoliz Plaza, Harare</p>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-2xl shadow-xl p-6 hover:shadow-2xl transition-shadow duration-300">
              <div className="flex items-center space-x-4">
                <div className="p-3 bg-gradient-to-r from-secondary to-accent rounded-xl">
                  <Mail className="w-6 h-6 text-white" />
                </div>
                <div>
                  <h3 className="font-semibold text-lg">Email Us</h3>
                  <a href="mailto:info@buildfast.co.zw" className="text-gray-600 hover:text-primary transition-colors">
                    info@buildfast.co.zw
                  </a>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-2xl shadow-xl p-6 hover:shadow-2xl transition-shadow duration-300">
              <div className="flex items-center space-x-4">
                <div className="p-3 bg-gradient-to-r from-accent to-primary rounded-xl">
                  <Phone className="w-6 h-6 text-white" />
                </div>
                <div>
                  <h3 className="font-semibold text-lg">Call Us</h3>
                  <a href="tel:+263776347735" className="text-gray-600 hover:text-primary transition-colors">
                    +263 776 347 735
                  </a>
                </div>
              </div>
            </div>
          </motion.div>
        </div>

        {/* Map Section */}
        <motion.div 
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="mt-16"
        >
          <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
            <iframe
              title="Office Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3798.225517258067!2d31.047399015353085!3d-17.828917987827373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1931a4f3f06beccd%3A0x1a82d14d840b163d!2sHarare%20St%20%26%20Speke%20Ave%2C%20Harare%2C%20Zimbabwe!5e0!3m2!1sen!2s!4v1645784892834!5m2!1sen!2s"
              className="w-full h-[400px]"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            />
          </div>
        </motion.div>
      </div>
    </main>
  );
};