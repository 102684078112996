import React from 'react';
import Kommunicate from "@kommunicate/kommunicate-chatbot-plugin";
import { HelmetProvider } from 'react-helmet-async';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

 Kommunicate.init("2a1a32720930aec33f23073bfde1fd50a", {
  automaticChatOpenOnNavigation: true,
  popupWidget: true
});

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);

