// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyAKlA0QHepzwd1yW__Y5RoxaZw8MZAmXuk",
  authDomain: "buildfast-7c1b6.firebaseapp.com",
  projectId: "buildfast-7c1b6",
  storageBucket: "buildfast-7c1b6.appspot.com",
  messagingSenderId: "215878178574",
  appId: "1:215878178574:web:9974bd8e354f918672695f",
  measurementId: "G-Q8V3N6K6CD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);