import React from 'react';
import { Leaf, Building2, Ruler, Cog, MapPin, HardHat } from 'lucide-react';

const sustainabilityFeatures = [
  {
    icon: Building2,
    title: 'Sustainable Architecture',
    description: 'Eco-friendly building designs that minimize environmental impact while maximizing efficiency and comfort.',
    benefits: ['Energy-efficient designs', 'Use of sustainable materials', 'Passive solar techniques']
  },
  {
    icon: Ruler,
    title: 'Green Quantity Surveying',
    description: 'Cost-effective solutions that prioritize sustainable materials and practices without compromising quality.',
    benefits: ['Lifecycle cost analysis', 'Sustainable material sourcing', 'Waste reduction strategies']
  },
  {
    icon: Cog,
    title: 'Eco Engineering',
    description: 'Innovative engineering solutions that reduce carbon footprint and enhance building performance.',
    benefits: ['Renewable energy systems', 'Water conservation methods', 'Smart building technologies']
  },
  {
    icon: MapPin,
    title: 'Sustainable Town Planning',
    description: 'Urban planning that creates environmentally conscious communities and promotes sustainable living.',
    benefits: ['Green spaces integration', 'Walkable communities', 'Public transport optimization']
  },
  {
    icon: HardHat,
    title: 'Green Construction',
    description: 'Construction practices that minimize waste, reduce emissions, and utilize eco-friendly techniques.',
    benefits: ['Recycled materials usage', 'Low-emission equipment', 'Waste management protocols']
  }
];

export const Sustainability = () => {
  return (
    <div className="bg-green-50 py-12 px-4">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-12">
          <Leaf className="w-12 h-12 text-green-600 mx-auto mb-4" />
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Sustainable Solutions for a Better Future</h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Our commitment to sustainability drives every aspect of our services, ensuring environmentally responsible outcomes for all projects.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {sustainabilityFeatures.map((feature, index) => (
            <div 
              key={index} 
              className="bg-white rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300"
            >
              <div className="mb-4">
                <feature.icon className="w-8 h-8 text-green-600 mb-2" />
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600 mb-4">{feature.description}</p>
              </div>
              <ul className="space-y-2">
                {feature.benefits.map((benefit, benefitIndex) => (
                  <li key={benefitIndex} className="flex items-center">
                    <Leaf className="w-4 h-4 text-green-500 mr-2 flex-shrink-0" />
                    <span className="text-gray-700">{benefit}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};