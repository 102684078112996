import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X, ChevronDown } from 'lucide-react';
import Logo from "../Assets/logo.png";

export const Header = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const closeMenus = () => {
    setActiveDropdown(null);
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeMenus();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const dropdownVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.2 } },
    exit: { opacity: 0, y: -10, transition: { duration: 0.2 } }
  };

  return (
    <nav className="bg-white border-b border-blue-800 shadow-lg sticky top-0 z-50">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-4 py-3">
        <Link to="/" className="flex items-center">
          <img src={Logo} alt="Build-fast" className="h-10 mr-2 sm:h-9" />
          <span className="text-3xl font-bold text-blue-800">Build-Fast</span>
        </Link>
        
        <button
          type="button"
          className="inline-flex items-center p-2 text-sm text-blue-800 rounded-lg md:hidden hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 transition-colors duration-200"
          onClick={toggleMobileMenu}
          aria-controls="navbar"
          aria-expanded={isMobileMenuOpen}
        >
          <span className="sr-only">Toggle main menu</span>
          {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        <div
          className={`${
            isMobileMenuOpen ? 'block' : 'hidden'
          } w-full md:block md:w-auto`}
          id="navbar"
          ref={dropdownRef}
        >
          <ul className="flex flex-col p-4 md:p-0 mt-4 md:flex-row md:space-x-6 md:mt-0 text-lg font-medium">
            {['Home', 'Projects', 'Gallery', 'Contact Us'].map((item) => (
              <li key={item}>
                <NavLink
                  to={item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`}
                  className={({ isActive }) =>
                    `block px-3 py-2 rounded-md transition-colors duration-200 ${
                      isActive
                        ? 'text-blue-800 bg-yellow-100'
                        : 'text-blue-600 hover:text-yellow-400 hover:bg-blue-50'
                    }`
                  }
                  onClick={closeMenus}  // Close menu after clicking an item
                >
                  {item}
                </NavLink>
              </li>
            ))}
            {['About Us', 'Services'].map((item) => (
              <li key={item} className="relative">
                <button
                  onClick={() => toggleDropdown(item)}
                  className="flex items-center w-full text-left px-3 py-2 text-blue-600 hover:text-yellow-400 hover:bg-blue-50 rounded-md transition-colors duration-200"
                  aria-expanded={activeDropdown === item}
                >
                  {item}
                  <ChevronDown size={16} className="ml-1" />
                </button>
                <AnimatePresence>
                  {activeDropdown === item && (
                    <motion.div
                      variants={dropdownVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      className="absolute z-10 bg-white shadow-lg rounded-lg mt-2 py-2 w-48"
                    >
                      {item === 'About Us'
                        ? [
                            <Link
                              key="About the Company"
                              to="/about_build_fast"
                              className="block px-4 py-2 text-blue-600 hover:bg-yellow-100 transition-colors duration-200"
                              onClick={closeMenus}  // Close menu after selection
                            >
                              About the Company
                            </Link>,
                            <Link
                              key="Our Team"
                              to="/team"
                              className="block px-4 py-2 text-blue-600 hover:bg-yellow-100 transition-colors duration-200"
                              onClick={closeMenus}  // Close menu after selection
                            >
                              Our Team
                            </Link>
                          ]
                        : ['Services', 'Architecture','Project Management', 'Quantity Surveying', 'Engineering', 'Town Planning', 'Construction'].map((subItem) => (
                            <Link
                              key={subItem}
                              to={`/services/${subItem.toLowerCase().replace(/\s+/g, '-')}`}
                              className="block px-4 py-2 text-blue-600 hover:bg-yellow-100 transition-colors duration-200"
                              onClick={closeMenus}  // Close menu after selection
                            >
                              {subItem}
                            </Link>
                          ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </li>
            ))}
          </ul>

          {/* Render "Get a Quote" in mobile view */}
          <div className="md:hidden mt-4">
            <Link
              to="/quotation"
              className="bg-yellow-400 hover:bg-yellow-500 text-blue-800 font-bold py-2 px-4 rounded-lg shadow-md transition-colors duration-200"
              onClick={closeMenus}  // Close menu after clicking
            >
              Get a Quote
            </Link>
          </div>
        </div>

        <div className="hidden md:flex">
          <Link
            to="/quotation"
            className="bg-yellow-400 hover:bg-yellow-500 text-blue-800 font-bold py-2 px-4 rounded-lg shadow-md transition-colors duration-200"
          >
            Get a Quote
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Header;






