/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import { storage, auth } from '../components/config/firebase';
import { ref, listAll, getDownloadURL, deleteObject } from 'firebase/storage';
import { onAuthStateChanged } from 'firebase/auth';
import { ImageIcon, Loader, Upload, Trash2, ZoomIn, Download, Search, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export const Gallery = ({title}) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState('all');

    useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    fetchImages();

    return () => unsubscribe();
  }, []);

  const fetchImages = async () => {
    setLoading(true);
    setError(null);
    try {
      const imagesRef = ref(storage, 'uploads');
      const result = await listAll(imagesRef);
      
      const urlPromises = result.items.map(async (item) => {
        try {
          const url = await getDownloadURL(item);
          return {
            url,
            name: item.name,
            fullPath: item.fullPath
          };
        } catch (err) {
          console.error(`Error fetching URL for ${item.name}:`, err);
          return null;
        }
      });

      const imageUrls = (await Promise.all(urlPromises)).filter(item => item !== null);
      setImages(imageUrls);
    } catch (err) {
      console.error('Error fetching images:', err);
      setError('Failed to fetch images. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (image, e) => {
    e.stopPropagation(); // Prevent opening the modal
    if (!user) return;
    
    if (window.confirm('Are you sure you want to delete this image?')) {
      setDeleteLoading(true);
      try {
        const imageRef = ref(storage, image.fullPath);
        await deleteObject(imageRef);
        setImages(prevImages => prevImages.filter(img => img.fullPath !== image.fullPath));
      } catch (err) {
        console.error('Error deleting image:', err);
        alert('Failed to delete image. Please try again.');
      } finally {
        setDeleteLoading(false);
      }
    }
  };

  const downloadImage = async (image, e) => {
    e.stopPropagation(); // Prevent opening the modal
    try {
      const response = await fetch(image.url);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = image.name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      console.error('Error downloading image:', err);
      alert('Failed to download image. Please try again.');
    }
  };

  const categories = ['all', 'interior', 'exterior', 'construction', 'design'];

  const filteredImages = images.map((image, index) => ({
    ...image,
    id: index,
    title: image.name,
    category: 'all',
    description: ''
  }));

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <main className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
          <h2 className="text-2xl font-bold">Image Gallery</h2>
          <div className="flex items-center gap-4">
            {error && (
              <button 
                onClick={fetchImages} 
                className="text-blue-500 hover:text-blue-700 transition-colors"
              >
                Retry
              </button>
            )}
          </div>
        </div>

        {/* Enhanced Search & Filter Section */}
        <section className="sticky top-0 z-30 bg-white shadow-lg border-b border-gray-100">
          <div className="max-w-7xl mx-auto px-4 py-6">
            <div className="flex flex-col md:flex-row gap-6 items-center justify-between">
              {/* Search Bar */}
              <div className="relative w-full md:w-64">
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-blue-800/50" size={20} />
                <input
                  type="text"
                  placeholder="Search gallery..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pl-10 pr-4 py-3 rounded-xl border-2 border-gray-200 focus:border-yellow-400 focus:ring-2 focus:ring-yellow-400/50 transition-all duration-300"
                />
              </div>

              {/* Category Filters */}
              <div className="flex flex-wrap gap-3 justify-center md:justify-end">
                {categories.map((category) => (
                  <motion.button
                    key={category}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setFilter(category)}
                    className={`px-6 py-3 rounded-xl font-medium transition-all duration-300 ${
                      filter === category
                        ? 'bg-blue-800 text-white shadow-lg'
                        : 'bg-gray-100 text-blue-800 hover:bg-gray-200'
                    }`}
                  >
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                  </motion.button>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Enhanced Gallery Grid */}
        <section className="py-16 px-4">
          <div className="max-w-7xl mx-auto">
            <motion.div 
              className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              {filteredImages
                .filter(image => filter === 'all' || image.category?.toLowerCase() === filter)
                .map((image, index) => (
                  <motion.div
                    key={image.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    onClick={() => setSelectedImage(image)}
                    className="group cursor-pointer relative overflow-hidden rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2"
                  >
                    <div className="aspect-w-4 aspect-h-3">
                      <img 
                        src={image.url} 
                        alt={image.title}
                        className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-blue-800/90 via-blue-800/40 to-transparent opacity-0 group-hover:opacity-100 transition-all duration-300">
                        <div className="absolute bottom-0 left-0 right-0 p-6">
                          <h3 className="text-xl font-bold text-white mb-2">{image.title}</h3>
                          <div className="flex items-center gap-2">
                            <span className="px-3 py-1 bg-yellow-400 text-blue-800 text-sm font-semibold rounded-full">
                              {image.category}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
              ))}
            </motion.div>
          </div>
        </section>

        {/* Enhanced Image Modal */}
        {selectedImage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 bg-black/95 flex items-center justify-center p-4"
            onClick={() => setSelectedImage(null)}
          >
            <div className="relative max-w-7xl w-full" onClick={e => e.stopPropagation()}>
              <button 
                className="absolute -top-12 right-0 text-white hover:text-yellow-400 transition-colors"
                onClick={() => setSelectedImage(null)}
              >
                <X size={24} />
              </button>
              <div className="bg-white rounded-2xl overflow-hidden shadow-2xl">
                <img
                  src={selectedImage.url}
                  alt={selectedImage.title}
                  className="w-full max-h-[80vh] object-contain"
                />
                <div className="p-6">
                  <h3 className="text-2xl font-bold text-blue-800 mb-2">{selectedImage.title}</h3>
                  <span className="px-3 py-1 bg-yellow-400 text-blue-800 text-sm font-semibold rounded-full">
                    {selectedImage.category}
                  </span>
                  {selectedImage.description && (
                    <p className="mt-4 text-gray-600">{selectedImage.description}</p>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        )}

        {/* Keep existing error handling */}
        {error && (
          <div className="text-center py-12">
            <p className="text-red-600">{error}</p>
          </div>
        )}
      </div>
    </main>
  );
};
