import React, { useState, useEffect } from 'react';
import { storage, auth } from './components/config/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut,
  onAuthStateChanged
} from 'firebase/auth';
import { Upload, X, CheckCircle, AlertCircle } from 'lucide-react';

export const ImageUploader = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState('');
  const [activeTab, setActiveTab] = useState('signin');
  
  // Existing state from ImageUploader
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  
  // New state for auth forms
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setAuthError('');
    } catch (error) {
      setAuthError(error.message);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      setAuthError('');
    } catch (error) {
      setAuthError(error.message);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Existing methods from ImageUploader
  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    setErrorMessage('');
  };

  const removeFile = (index) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setUploadProgress(prev => {
      const newProgress = { ...prev };
      delete newProgress[index];
      return newProgress;
    });
  };

  const uploadFile = async (file, index) => {
    const fileRef = ref(storage, `uploads/${file.name}`);
    setUploadProgress(prev => ({
      ...prev,
      [index]: { status: 'uploading', progress: 0 }
    }));

    try {
      await uploadBytes(fileRef, file);
      const downloadURL = await getDownloadURL(fileRef);
      setUploadProgress(prev => ({
        ...prev,
        [index]: { status: 'success', url: downloadURL }
      }));
    } catch (error) {
      setUploadProgress(prev => ({
        ...prev,
        [index]: { status: 'error', error: error.message }
      }));
    }
  };

  const handleUpload = async () => {
    setUploading(true);
    const uploadPromises = selectedFiles.map((file, index) => uploadFile(file, index));
    await Promise.all(uploadPromises);
    setUploading(false);
  };

  const clearAll = () => {
    setSelectedFiles([]);
    setUploadProgress({});
    setErrorMessage('');
  };

  if (loading) {
    return <div className="flex justify-center items-center h-64">Loading...</div>;
  }

  if (!user) {
    return (
      <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4">Authentication Required</h2>
        <p className="text-gray-600 mb-4">Please sign in or create an account to access the uploader.</p>
        
        <div className="flex mb-4">
          <button
            onClick={() => setActiveTab('signin')}
            className={`flex-1 py-2 ${activeTab === 'signin' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            Sign In
          </button>
          <button
            onClick={() => setActiveTab('signup')}
            className={`flex-1 py-2 ${activeTab === 'signup' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            Sign Up
          </button>
        </div>

        {activeTab === 'signin' ? (
          <form onSubmit={handleSignIn} className="space-y-4">
            <div>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <button type="submit" className="w-full py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600">
              Sign In
            </button>
          </form>
        ) : (
          <form onSubmit={handleSignUp} className="space-y-4">
            <div>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <button type="submit" className="w-full py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600">
              Sign Up
            </button>
          </form>
        )}

        {authError && (
          <div className="mt-4 p-2 bg-red-100 text-red-700 rounded">
            {authError}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Uploader</h2>
        <button 
          onClick={handleSignOut}
          className="py-2 px-4 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
        >
          Sign Out
        </button>
      </div>
      
      <div className="p-4 space-y-4">
        <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
          <input
            type="file"
            multiple
            accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            onChange={handleFileSelect}
            className="hidden"
            id="file-upload"
            disabled={uploading}
          />
          <label
            htmlFor="file-upload"
            className="cursor-pointer flex flex-col items-center justify-center"
          >
            <Upload className="w-12 h-12 text-gray-400" />
            <p className="mt-2 text-sm text-gray-600">Click or drag files to upload</p>
          </label>
        </div>

        {errorMessage && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}

        {selectedFiles.length > 0 && (
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold">Selected Files</h3>
              <button
                onClick={clearAll}
                className="text-red-500 hover:text-red-700"
              >
                Clear All
              </button>
            </div>
            
            <div className="space-y-2">
              {selectedFiles.map((file, index) => (
                <div key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded">
                  <span className="truncate max-w-xs">{file.name}</span>
                  <div className="flex items-center space-x-2">
                    {uploadProgress[index]?.status === 'uploading' && (
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-500"></div>
                    )}
                    {uploadProgress[index]?.status === 'success' && (
                      <CheckCircle className="w-4 h-4 text-green-500" />
                    )}
                    {uploadProgress[index]?.status === 'error' && (
                      <div className="group relative">
                        <AlertCircle className="w-4 h-4 text-red-500" />
                        <div className="hidden group-hover:block absolute z-10 w-48 p-2 mt-1 text-sm text-white bg-gray-700 rounded-lg shadow-lg -right-1 top-full">
                          {uploadProgress[index]?.error}
                        </div>
                      </div>
                    )}
                    <button
                      onClick={() => removeFile(index)}
                      className="text-gray-500 hover:text-red-500"
                      disabled={uploading}
                    >
                      <X className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {selectedFiles.length > 0 && (
          <button
            onClick={handleUpload}
            disabled={uploading}
            className={`w-full py-2 px-4 rounded ${
              uploading
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-500 hover:bg-blue-600 text-white'
            }`}
          >
            {uploading ? 'Uploading...' : `Upload ${selectedFiles.length} Files`}
          </button>
        )}
      </div>
    </div>
  );
};
