import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Instagram, Linkedin } from 'lucide-react';
import { FaTiktok } from 'react-icons/fa';
import Logo from "../Assets/logo.png";
import { OptimizedImage } from './OptimizedImage';

const FooterSection = ({ title, links }) => (
  <div className="mb-8 lg:mb-0">
    <h2 className="mb-4 sm:mb-6 text-lg font-semibold text-dark-blue">{title}</h2>
    <ul className="space-y-3 sm:space-y-4 text-gray-600">
      {links.map((link, index) => (
        <li key={index}>
          <Link 
            to={link.to} 
            className="text-sm sm:text-base hover:text-yellow-400 transition-colors duration-300"
          >
            {link.text}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

const SocialIcon = ({ Icon, href, label }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="text-gray-600 hover:text-yellow-400 transition-colors duration-300"
    aria-label={label}
  >
    <Icon className="w-5 h-5 sm:w-6 sm:h-6" />
  </a>
);

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  const sections = [
    {
      title: "Company",
      links: [
        { to: "/about_build_fast", text: "About Us" },
        { to: "/careers", text: "Careers" },
        { to: "/projects", text: "Projects" },
      ],
    },
    {
      title: "Services",
      links: [
        { to: "/services/architecture", text: "Architecture" },
        { to: "/services/quantity-surveying", text: "Quantity Surveying" },
        { to: "/services/engineering", text: "Engineering" },
        { to: "/services/town-planning", text: "Town Planning" },
        { to: "/services/construction", text: "Construction" },
      ],
    },
    {
      title: "Resources",
      links: [
        { to: "/faq", text: "FAQ" },
        { to: "/sustainability", text: "Sustainability" },
        { to: "/contact-us", text: "Contact Us" },
      ],
    },
    {
      title: "Legal",
      links: [
        { to: "/privacy-policy", text: "Privacy Policy" },
        { to: "/terms-conditions", text: "Terms & Conditions" },
        { to: "/safety", text: "Safety Commitment" },
      ],
    },
  ];

  return (
    <footer className="bg-white text-dark-blue" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">Footer</h2>
      <div className="max-w-7xl mx-auto py-8 sm:py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-8 lg:gap-12">
          {/* Company Info Section */}
          <div className="lg:col-span-2 space-y-6 sm:space-y-8">
            <OptimizedImage
              className="h-8 w-auto sm:h-9 md:h-10"
              src={Logo}
              alt="BuildFast Construction Logo"
              width={160}
              height={40}
            />
            <p className="text-sm sm:text-base text-gray-600 max-w-md">
              Building tomorrow's world with today's expertise. Quality, innovation, and reliability in every project.
            </p>
            <div className="flex flex-wrap gap-4 sm:gap-6">
              <SocialIcon 
                href="https://www.facebook.com/buildfastZIM" 
                Icon={Facebook} 
                label="Facebook"
              />
              <SocialIcon 
                href="https://www.instagram.com/buildfastzw/" 
                Icon={Instagram} 
                label="Instagram"
              />
              <SocialIcon 
                href="https://www.linkedin.com/in/courage-makonese-643687331" 
                Icon={Linkedin} 
                label="LinkedIn"
              />
              <SocialIcon 
                href="https://www.tiktok.com/@build_fast" 
                Icon={FaTiktok} 
                label="TikTok"
              />
              <SocialIcon 
                href="https://x.com/buildfastzim" 
                Icon={Twitter} 
                label="Twitter"
              />
            </div>
          </div>

          {/* Footer Sections */}
          <div className="lg:col-span-3 grid grid-cols-2 md:grid-cols-4 gap-8">
            {sections.map((section, index) => (
              <FooterSection key={index} {...section} />
            ))}
          </div>
        </div>

        {/* Copyright Section */}
        <div className="mt-8 sm:mt-12 pt-8 border-t border-gray-300">
          <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
            <p className="text-sm sm:text-base text-gray-600">
              &copy; {currentYear} BuildFast Construction. All rights reserved.
            </p>
            <p className="text-sm sm:text-base text-gray-600">
              Website Designed & Hosted by{' '}
              <a 
                href="https://talentmundwa.me" 
                className="hover:text-yellow-400 transition-colors duration-300"
                target="_blank"
                rel="noopener noreferrer"
              >
                Epic Web Dev
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
















