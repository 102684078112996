import React, { Suspense } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const FallbackImage = ({ src, alt, className }) => (
  <div className="animate-pulse bg-gradient-to-r from-primary-light/20 to-accent-light/20 rounded-lg">
    <img 
      src={src} 
      alt={alt} 
      className={className} 
      loading="lazy"
    />
  </div>
);

export const OptimizedImage = ({ src, alt, className, width, height }) => {
  return (
    <Suspense fallback={<FallbackImage src={src} alt={alt} className={className} />}>
      <LazyLoadImage
        src={src}
        alt={alt}
        className={className}
        effect="blur"
        width={width}
        height={height}
        loading="lazy"
        placeholderSrc={`${src}?w=50`}
      />
    </Suspense>
  );
}; 